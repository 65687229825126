import React, { useEffect } from 'react';
import back from "./back.png";
import { useNavigate } from 'react-router-dom';
import "./back.css";

function Back() {
    const navigate = useNavigate();

    function handleBack() {
        navigate(-1);
    }

    useEffect(() => {   
        if (window.Telegram?.WebApp) {
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(handleBack);
        }

        return () => {
            if (window.Telegram?.WebApp) {
                window.Telegram.WebApp.BackButton.offClick(handleBack);
            }
        };
    }, [navigate]);


    return (
        <>
        </>
    );
}

export default Back;
