import React from 'react'
import "./SiteDisabled.scss"
import img from "./photo.png"
function SiteDisabled() {
    return (
        <div className='outer_container_disabled'>
            <div className='container_glow_disabled'>
                <div className="gradient-text">TECHNICAL WORK</div>
                <img src={img}></img>
            </div>
        </div>
    )
}

export default SiteDisabled