import React, { useState } from 'react';
import "./stat.css";
import { useAppData } from '../../AppContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLeague } from './LeagueContext';

const StatBlock = () => {
    const { t } = useTranslation();
    const { passiveIncomePerHour, clickCost, dollar_coin, total_coins_earned } = useAppData();
    const navigate = useNavigate();
    const leagueInfo = useLeague();

    const leagues = [
        { color: '#C7C7C7', threshold: 0 },
        { color: '#91B3F8', threshold: 300000 },
        { color: '#F3CD46', threshold: 1000000 },
        { color: '#37CDEE', threshold: 10000000 },
        { color: '#C019CD', threshold: 50000000 },
    ];

    const formatNumberLeague = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(0) + 'B';
        } else if (num >= 1000000) {
            return (num / 1000000).toFixed(0) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(0) + 'K';
        }
        return num;
    };

    const formatNumber = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(1) + 'B';
        } else if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 3 }) + 'K';
        }
        return num;
    };

    const getNextLeagueThreshold = () => {
        const thresholds = [300000, 1000000, 10000000, 50000000];
        for (let threshold of thresholds) {
            if (total_coins_earned < threshold) {
                return threshold;
            }
        }
        return "Max";
    };

    const getCurrentLeague = () => {
        for (let i = leagues.length - 1; i >= 0; i--) {
            if (total_coins_earned >= leagues[i].threshold) {
                return i;
            }
        }
        return 0;
    };

    const [isNextLeagueShown, setIsNextLeagueShown] = useState(true);

    const toggleNextLeague = () => {
        setIsNextLeagueShown(!isNextLeagueShown);
    };

    const currentLeagueIndex = getCurrentLeague();
    const currentLeague = leagues[currentLeagueIndex];

    const filledStarsArray = Array(currentLeagueIndex + 1).fill(currentLeague.color);
    const emptyStarsArray = Array(5 - filledStarsArray.length).fill('#000000');

    return (
        <div className="stat-block">
            <div className="stat-item" onClick={() => navigate("/boosts")}>
                <div className='stat_text' style={{ color: "#FCD36B" }}>{t('statBlock.profitPerClick')}</div>
                <div>
                    <span style={{ fontSize: "18px" }}>+{clickCost}</span>
                    <img src={dollar_coin} alt={t('statBlock.profitPerClick')} />
                </div>
            </div>
            <div className="stat-item" onClick={toggleNextLeague}>
                <div className='stat_text' style={{ color: "#FFA02B" }}>{t('statBlock.nextLeague')}</div>
                <div>
                    <span style={{ fontSize: "18px" }}>
                        {formatNumberLeague(getNextLeagueThreshold())}
                    </span>
                </div>
                 {/* <div className='stat_text' style={{ color: currentLeague.color }}>Your league progress</div>
                <div style={{ display: 'flex', gap: '2px', flexDirection: 'row', }}>
                    {filledStarsArray.map((color, index) => (
                        <svg key={index} height="20px" width="20px" fill={color} viewBox="0 0 47.94 47.94" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z" />
                        </svg>
                    ))}
                    {emptyStarsArray.map((color, index) => (
                        <svg key={index + filledStarsArray.length} height="20px" width="20px" fill={color} viewBox="0 0 47.94 47.94" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z" />
                        </svg>
                    ))}
                </div> */}
            </div>
            <div className="stat-item" onClick={() => navigate("/mine")}>
                <div className='stat_text' style={{ color: "#7CFAA4" }}>{t('statBlock.profitPerHour')}</div>
                <div>
                    <span style={{ fontSize: "18px" }}>{passiveIncomePerHour == null ? 0 : `+${formatNumber(passiveIncomePerHour)}`}</span>
                    <img src={dollar_coin} alt={t('statBlock.profitPerHour')} />
                </div>
            </div>
        </div>
    );
};

export default StatBlock;
