import React, { useEffect, useState } from 'react';
import Footer from '../Footer/Footer';
import Petcard from './Petcard';
import "./ChoosePet.scss";
import { getAllPets } from '../../http';
import pet from "./pet.png"
function ChoosePet() {
    const [pets, setPets] = useState([]);

    const getAllPetstData = async () => {
        try {
            const data = await getAllPets();
            setPets(data.pets);
        } catch (error) {
            console.error('Failed to fetch pets', error);
        }
    };

    useEffect(() => {
        getAllPetstData();
    }, []);

    return (
        <div className='choosepet_container'>
            <div className='choose_title'>Choose Your Pet</div>
            {/* <div className='pets_container'>
                {pets.map(pet => (
                    <Petcard key={pet.id} pet={pet} />
                ))}
            </div> */}
            <img className='choose_pet_img' src={pet}></img>

            <div className='choose_coming'>Coming Soon</div>

            <div className='choose_description'>Earn up to <span className='choose_reward'>$10,000</span> by Playing with pet characters, using abilities, boosts and powerups.</div>

            <Footer />
        </div>
    );
}

export default ChoosePet;
