import React from 'react'
import poiner from "./poiner.png";
import { useNavigate } from 'react-router-dom';
import "./navigate.css"
function Navigate({ position, navigateTo }) {
    const navigate = useNavigate();

    function handleBack() {
        navigate(navigateTo);
    }

    return (
        <img src={poiner} onClick={handleBack} className={position == "left" ? "pointer left" : "pointer right"} alt="Back"></img>
    )
}

export default Navigate

