import React, { useEffect, useRef, useState } from 'react';
import "./MainPet.scss";
import { useAppData } from '../../../AppContext';
import Navigate from "../../NavigateBtn/Navigate";
import Footer from '../../Footer/Footer';
import dollar_coin from "./dollar_coin.png";
import { feedPet } from '../../../http';
import Header from './Header';
import ToFood from './Modals/ToFood';
import banana from './🍌.png';
import carrotImage from './🥕.png';
import fish from './🐟.png';
import meat from './🍖.png';
import sleepIcon from './😴.png';
import starIcon from './⭐.png';
import thanks from "./thanks.png";
import { useTranslation } from 'react-i18next';

function MainPet() {
    const { t } = useTranslation();
    const { coins, Pet, handleEditCoins, setTriggerRender } = useAppData();
    const [pet, setPetData] = useState(Pet);
    const [totalSpent, setTotalSpent] = useState(pet.total_spent);
    const [currentLevelIndex, setCurrentLevelIndex] = useState(0);
    const [fedTimes, setFedTimes] = useState(Pet.fed_times);
    const [showFood, setShowFood] = useState(true);
    const [shopModal, setShopModal] = useState(false);
    const [showModalPet, setShowModalPet] = useState(false);
    const [carrots, setCarrots] = useState([]);
    const carrotCount = 20;

    const [nextFeedTime, setNextFeedTime] = useState(null);
    const [countdown, setCountdown] = useState(null);

    const cooldown = 10800;

    useEffect(() => {
        const lastFedTimestamp = pet.last_time_fed;
        const nextFeedTimestamp = lastFedTimestamp + cooldown;
        const currentTime = Math.floor(Date.now() / 1000);
        if (currentTime < nextFeedTimestamp) {
            setNextFeedTime(nextFeedTimestamp);
        }
    }, [pet.last_time_fed]);

    useEffect(() => {
        let interval = null;
        if (nextFeedTime) {
            interval = setInterval(() => {
                const currentTime = Math.floor(Date.now() / 1000);
                const secondsLeft = nextFeedTime - currentTime;
                setCountdown(secondsLeft > 0 ? formatTime(secondsLeft) : "0:00:00");
                if (secondsLeft <= 0) {
                    clearInterval(interval);
                    setNextFeedTime(null);
                }
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [nextFeedTime]);

    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    useEffect(() => {
        if (window.Telegram.WebApp.BackButton.isVisible) {
            window.Telegram.WebApp.BackButton.hide();
        }
    }, []);

    const coinBar = useRef(null);

    const defaultFloatingFood = [banana, carrotImage, fish, meat];
    const sleepFloating = [sleepIcon, starIcon];

    const levels = [
        { level: 1, required: 30000, nextLevel: "30K" },
        { level: 2, required: 60000, nextLevel: "60K" },
        { level: 3, required: 120000, nextLevel: "120K" },
        { level: 4, required: 240000, nextLevel: "240K" },
        { level: 5, required: 580000, nextLevel: "580K" },
        { level: 6, required: 1160000, nextLevel: "1.16M" },
        { level: 7, required: 2320000, nextLevel: "2.32M" },
        { level: 8, required: 4640000, nextLevel: "4.64M" },
        { level: 9, required: 9280000, nextLevel: "9.28M" },
        { level: 10, required: 18560000, nextLevel: "18.56M" },
        { level: 11, required: 37120000, nextLevel: "37.12M" },
        { level: 12, required: 74240000, nextLevel: "74.24M" },
        { level: 13, required: 296960000, nextLevel: "296.96M" }
    ];

    const feedCosts = [10000, 20000, 40000, 80000, 160000, 320000, 640000, 1280000, 2560000, 5120000];



    const getPetLogo = () => {
        if (fedTimes === 0) {
            return pet.logo_hungry;
        } else if (fedTimes >= 3) {
            return pet.logo_resting;
        } else {
            return pet.logo_without_background;
        }
    };

    const handleFeed = async () => {
        if (fedTimes < 3) {
            if (coins > feedCosts[currentLevelIndex]) {
                await feedPet();
                handleEditCoins(-feedCosts[currentLevelIndex]);
                setFedTimes(prevTimes => prevTimes + 1);
                setTotalSpent(prevSpent => prevSpent + feedCosts[currentLevelIndex]);
                setShowFood(true);
                setTimeout(() => {
                    setShowFood(false);
                }, 2000);
            }
        }
    };

    useEffect(() => {
        const levelIndex = levels.findIndex(level => totalSpent < level.required);
        setCurrentLevelIndex(levelIndex === -1 ? levels.length - 1 : levelIndex);
    }, [totalSpent]);

    useEffect(() => {
        if (coinBar.current && currentLevelIndex < levels.length) {
            const currentLevelRequirement = levels[currentLevelIndex].required;
            const progress = totalSpent / currentLevelRequirement;
            coinBar.current.style.width = `${Math.min(100, progress * 100)}%`;
        }
    }, [totalSpent, currentLevelIndex]);

    useEffect(() => {
        setFedTimes(pet.fed_times);
        setTotalSpent(pet.total_spent);
    }, [pet.fed_times, pet.total_spent]);

    const getIcons = () => {
        if (pet.selected_food === "") {
            return defaultFloatingFood;
        } else if (fedTimes >= 3) {
            return sleepFloating;
        } else {
            return [pet.selected_food_logo];
        }
    };

    const updateCarrotPosition = () => {
        setCarrots(prevCarrots =>
            prevCarrots.map(carrot => ({
                ...carrot,
                y: (carrot.y + carrot.speed) % window.innerHeight
            }))
        );
    };

    useEffect(() => {
        const initializeCarrots = () => {
            const newCarrots = Array.from({ length: carrotCount }, () => ({
                x: Math.random() * window.innerWidth,
                y: Math.random() * window.innerHeight - 300,
                speed: Math.random() * 2 + 1
            }));
            setCarrots(newCarrots);
        };

        initializeCarrots();

        const interval = setInterval(() => {
            updateCarrotPosition();
        }, 30);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const floatingFood = getIcons();
        const newCarrots = Array.from({ length: carrotCount }, (_, index) => ({
            img: floatingFood[index % floatingFood.length],
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight - 300,
            speed: Math.random() * 2 + 1
        }));
        setCarrots(newCarrots);
    }, [pet.selected_food, fedTimes]);

    function formatNumber(num) {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        }
        else return num;
    }

    return (
        <div className='mainpet_container'>
            {shopModal && <ToFood onClick={() => setShopModal(false)} />}

            {showModalPet && (
                <div className='outer_container_pet_modal'>
                    <div className='container_glow_mainpet_modal'>
                        <span className="close" onClick={() => setShowModalPet(false)}>&times;</span>
                        <img src={thanks} className='thanks_mainpet_img' alt="Thanks" />
                        <h1>{t('mainPet.thanks')}</h1>
                        <div>
                            {t('mainPet.notHungry')}
                            <span className="gradient_text_mainpet"> {countdown} </span>
                        </div>
                    </div>
                </div>
            )}

            <Navigate position="left" navigateTo="/" />
            <Header pet={pet} />
            <div className='outer-container_pet'>
                <div className='container_glow_mainpet'>
                    <div className='column'>
                        <div className="stats">
                            <img src={dollar_coin} className="stats-coin" alt="Coin-icon" />
                            <div id="balance" className="coin-count">{coins.toLocaleString()}</div>
                        </div>
                        <div className="coin-wrapper">
                            <div className="coin-bar">
                                <div className="coin" ref={coinBar}></div>
                            </div>
                            <div className="coin-counter">
                                <span className='green_mainpet'>{levels[currentLevelIndex]?.nextLevel} {t('mainPet.levelUp')} {pet.level + 1}</span>
                            </div>
                        </div>
                    </div>

                    <div className='pet_container'>
                        <img className='pet_img_main' src={getPetLogo()} alt="Your Pet" />
                        <div className='pet-glow'></div>
                        <div className='food'>
                            {carrots.map((carrot, index) => (
                                <div key={index} style={{ position: "absolute", left: carrot.x, top: carrot.y }}>
                                    <img src={carrot.img} className="food-fly-icon" alt="Floating Food" />
                                </div>
                            ))}
                        </div>
                    </div>

                    {pet.selected_food === "" ? (
                        <div className={`feed_btn ${fedTimes >= 3 ? 'disabled' : ''}`} onClick={() => setShopModal(true)}>
                            <div>{feedCosts[currentLevelIndex]}🌕</div>
                            <div className='d-flex'>
                                <div>{t('mainPet.feed')}</div>
                                {fedTimes < 3 && <img src={pet.selected_food_logo} className="food-feed" alt="Food" />}
                            </div>
                            <div>{fedTimes}/3</div>
                        </div>
                    ) : (
                        <div className='disabled_fed_container'>
                            <div className={`feed_btn ${fedTimes >= 3 ? 'disabled_btn' : ''}`} onClick={fedTimes >= 3 ? () => setShowModalPet(true) : handleFeed}>
                                <div>{feedCosts[currentLevelIndex]}🌕</div>
                                <div className='d-flex'>
                                    <div>{t('mainPet.feed')}</div>
                                    {fedTimes < 3 && <img src={pet.selected_food_logo} className="food-feed" alt="Food" />}
                                </div>
                                <div>{fedTimes}/3</div>
                            </div>

                            {fedTimes >= 3 && <div className="gradient_text_mainpet"> {countdown} </div>}
                        </div>
                    )}
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default MainPet;