import React, { useState, useEffect, useRef } from 'react';
import { useAppData } from '../../AppContext';
import { useTranslation } from 'react-i18next';
import Back from '../Back';
import Footer from '../Footer/Footer';
import "./leagues.scss";
import Background from '../../pages/background.png';

import Arrow from './back.png';
import Bronze from './Bronze.png';
import Silver from './Silver.png';
import Gold from './Gold.png';
import Platinum from './Platinum.png';
import Diamond from './Diamond.png';
import Master from './Master.png';
import Grandmaster from './Grandmaster.png';

export const Leagues = () => {
    const { t } = useTranslation();
    const { total_coins_earned, leagues } = useAppData();

    const leagueBar = useRef(null);

    const [currentIndex, setCurrentIndex] = useState(0);

    const leaguesWithLogos = leagues.map((league, index) => {
        let logo;
        switch (index) {
            case 0: logo = Bronze; break;
            case 1: logo = Silver; break;
            case 2: logo = Gold; break;
            case 3: logo = Platinum; break;
            case 4: logo = Diamond; break;
            case 5: logo = Master; break;
            case 6: logo = Grandmaster; break;
            default: logo = Bronze;
        }
        return { ...league, logo };
    });

    const findCurrentLeagueIndex = () => {
        for (let i = leaguesWithLogos.length - 1; i >= 0; i--) {
            if (leaguesWithLogos[i].coin_limit === "max" || total_coins_earned >= leaguesWithLogos[i].coin_limit) {
                return i;
            }
        }
        return 0;
    };

    useEffect(() => {
        const index = findCurrentLeagueIndex();
        setCurrentIndex(index);
    }, [total_coins_earned, leagues]);

    useEffect(() => {
        const updateLeagueBarWidth = () => {
            const currentLeague = leaguesWithLogos[currentIndex];
            if (currentLeague && leagueBar.current) {
                const coin_limit = currentLeague.coin_limit === "max" ? total_coins_earned : currentLeague.coin_limit;
                const width = currentLeague.coin_limit === "max"
                    ? 100
                    : (total_coins_earned / coin_limit) * 100;
                leagueBar.current.style.width = `${Math.min(width, 100)}%`;
            }
        };

        updateLeagueBarWidth();
    }, [total_coins_earned, currentIndex, leagues]);

    const nextLeague = () => {
        if (currentIndex < leaguesWithLogos.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const prevLeague = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const currentLeague = leaguesWithLogos[currentIndex];

    return (
        <div className='leagueListContainer' style={{
            height: '100vh',
            overflow: 'hidden',
            backgroundImage: `url(${Background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>
            <Back />

            <div className="leagueText">
                <h1>{currentLeague.name} League</h1>
                <h3>Your number of shares determines the league you enter.</h3>
            </div>

            <div className="trophy">
                <img src={currentLeague.logo} alt={`${currentLeague.name} trophy`} />
            </div>

            <div className="leagueBar">
                <div className="energy-counter">
                    {currentLeague.coin_limit === "max"
                        ? <span>MAX</span>
                        : <>
                            <span>{total_coins_earned}</span>/{currentLeague.coin_limit}
                        </>
                    }
                </div>
                <div className="energy-bar" ref={leagueBar} />
            </div>

            <div className="league-navigation">
                <button onClick={prevLeague} disabled={currentIndex === 0}>
                    <img src={Arrow} />
                </button>
                <button onClick={nextLeague} disabled={currentIndex === leaguesWithLogos.length - 1}>
                    <img src={Arrow} />
                </button>
            </div>

            <Footer />
        </div>
    );
};
