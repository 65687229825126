import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Back from '../Back';
import ComingSoon from '../ComingSoon/ComingSoon';
import { sendGame } from '../../http';

import nikiImg from '../../images/icons/niki.png';
import coinImg from '../../images/icons/coin.png';
import gameBoomImg from '../../images/games/nikiboom.png';
import gameSoonImg from '../../images/games/soon.png';
import controller from './controller.png';
import img1 from './1.png';
import img2 from './2.png';
import './games.scss';

export const Games = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    const handleCLick = () => {
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openLink('https://wrapper.playdeck.io/?url=https://games.playdeck.io/games/NikiBoom/index.html?telegram%3Dtrue%26lang%3Dru&token=eyJraWQiOiJiYzljZjY1OS05NWQyLTQ1NWQtYmYxMS1jMTJmMTM0ODhhMzUiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIyNzQ0NDgwNTYiLCJpc3MiOiJodHRwczpcL1wvb2EudG9ucGxheS5pbyIsImdhbWVLZXkiOiI0N3M5UWtQSE4yIiwiZ2FtZVNob3J0TmFtZSI6Ik5pa2lCb29tIiwiZXhwIjoxNzE4MTkxNzc2LCJ0eXBlIjoiR0FNRSIsImxvY2FsZSI6InJ1IiwiaWF0IjoxNzA5NTUxNzc2LCJhdXRob3JpdGllcyI6WyJST0xFX1VTRVIiLCJST0xFX1RFU1QiLCJST0xFX0tBU1NJUiJdLCJ1c2VybmFtZSI6InFzaGVwZWwiLCJsYWJlbHMiOlsid2hlZWxfdjIiLCJ3aGVlbF92Mi41Iiwid2hlZWxfZ3JpZF8xNDEyX3llbGxvdyIsInBheWVyIiwidG91cm5hbWVudHNfcGF5ZXIiXX0.RP78funs-DL63hLswUizIIA9klqUApk0FC89u3__bN0cP6Ft1DZzcox3xqLqcJc9AdKT3-dFzKoL90QxELRYG9QnBSoThirq2Qb1IMMqj5Ua6ueFV2FToVa2YsuVKVT6WaxBpzZi9PztW6BsfP0fl6ktEKpvNkS1-PG0n-v9JNeM3LD0AbhGIzd5fnFdWZsqMwsoC7WaWZGYFgKGK1sfN1QsfrKna_uYrVXo_lGZz63HcKf_ghBamq3K-cjBciFHm-Ea9MeSiAQSAxs-HlqRSnzxInMsKwpXfVtxXv4p3b8LqOf3TMV8xZkBP_HhvW7gENMWHHGJD7eoZVxa11kzwQ&theme=light&lang=ru&entropy=x0sdy&gameSessionId=73d68553-1ae1-4ae2-b2fb-320655b57e22&mode=gameapp#telegramId=274448056&tgShareScoreUrl=tg://share_game_score?hash=AXd2Ly3UQAFZDfxg1yKY6fsRtZVFSRpanlhNVKtoYcTQcqBwplkKh9LU2RkWO_5_');
        }
    };

    return (
        <>
            {show ? <ComingSoon onClick={() => setShow(false)} /> : null}

            <div className='outer-container_games'>
                <div className='container_glow_games'>
                    <Back />
                    <div className="tab_content_games">
                        <div className="tab-header">
                            <div className="tab-header-background"></div>
                            <img src={controller} className="tab-img" draggable="false" />
                            <h2 className="tab-title">{t('games.playOurGames')}</h2>
                        </div>
                        <h3 style={{ marginLeft: "-20px" }}>{t('games.ourGames')}</h3>
                        <div>
                            <div className="game_container">
                                <div className="nikiboom-game-info">
                                    <img src={gameBoomImg} alt="Niki Boom" />
                                    <p>{t('games.nikiBoom')}<img src={coinImg} className="coin-icon" alt="Coin-icon" /></p>
                                </div>
                                <button className="button_play_games button-purple" onClick={handleCLick}>{t('games.play')}</button>
                            </div>

                            <div className="game_container">
                                <div className="nikiboom-game-info">
                                    <img src={img2} alt="Meme Guys" />
                                    <p>{t('games.memeGuys')}</p>
                                </div>
                                <button className="button_play_games" onClick={() => setShow(true)}>{t('games.comingSoon')}</button>
                            </div>

                            <div className="game_container">
                                <div className="nikiboom-game-info">
                                    <img src={img1} alt="Niki World" />
                                    <p>{t('games.nikiWorld')}</p>
                                </div>
                                <button className="button_play_games" onClick={() => setShow(true)}>{t('games.comingSoon')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
