import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAppData } from '../../AppContext';

const LeagueContext = createContext();

export const LeagueProvider = ({ children }) => {
    const { total_coins_earned } = useAppData();
    const [leagueInfo, setLeagueInfo] = useState({ name: '' });

    const leagues = [
        { name: '1', threshold: 0 },
        { name: '2', threshold: 300000 },
        { name: '3', threshold: 1000000 },
        { name: '4', threshold: 10000000 },
        { name: '5', threshold: 50000000 },
        { name: '6', threshold: 100000000 },
        { name: '7', threshold: 1000000000 },
    ];

    useEffect(() => {
        const sortedLeagues = [...leagues].sort((a, b) => a.threshold - b.threshold);
        const currentLeague = sortedLeagues.find(league => total_coins_earned < league.threshold);
        if (currentLeague) {
            setLeagueInfo({ name: currentLeague.name });
        } else {
            const highestLeague = sortedLeagues[sortedLeagues.length - 1];
            setLeagueInfo({ name: highestLeague.name });
        }
    }, [total_coins_earned]);

    return (
        <LeagueContext.Provider value={leagueInfo}>
            {children}
        </LeagueContext.Provider>
    );
};

export const useLeague = () => useContext(LeagueContext);
