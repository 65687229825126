import React, { useState, useEffect } from 'react';
import gratsImg from '../../images/icons/grats.png';
import teamDefaultImg from '../../images/teams/teamlogo_1.jpg';
import { getTopTeams, getSearchTeams } from '../../http';
import Back from '../Back';
import "./team.scss"
import { useNavigate } from 'react-router-dom';
import ComingSoon from '../ComingSoon/ComingSoon';
import img from "./img1.png"

export const TeamExplorer = () => {
    const [teamList, setTeamList] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [show, setShow] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchTeams = async () => {
            const teamsData = await getTopTeams();
            if (teamsData) {
                setTeamList(teamsData.teams);
            }
        };

        fetchTeams();
    }, []);

    const handleSearchInputChange = async (event) => {
        const searchTerm = event.target.value;
        setSearchInput(searchTerm);

        if (searchTerm === '') {
            const teamsData = await getTopTeams();
            if (teamsData) {
                setTeamList(teamsData.teams);
            }
        } else {
            const searchResults = await getSearchTeams(searchTerm);
            if (Array.isArray(searchResults.teams)) {
                setTeamList(searchResults.teams);
            }
        }
    };

    return (
        <>
            {show ? <ComingSoon onClick={() => setShow(false)} /> : ""}

            <div className='league_container'>
                <Back />
                <div className='team_background' >
                    <div className="header_league">
                        <div>
                            <div className="header_league_background"></div>
                            <div className="header_container">
                                <div className="tab-header-background teamexplorer-header-background"></div>
                                <img src={gratsImg} className="tab-img" draggable="false" />
                                <h2 className="tab-title">How TEAM works?</h2>
                                <div className="tab-subtitle">Choose team and win tournaments</div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className='outer-container_team'>
                    <div className='container_glow_team'>
                        <div className='text_coming'>🔒 Сoming soon</div>
                        <img src={img} className='img_coming'></img>
                        <div className='glow_coming'></div>
                        {/* <button onClick={() => setShow(true)} className="button_long_friend button-orange">Create your Team</button> */}
                        {/*<input
                            type="text"
                            className="button_long_friend button-purple teamexplorer-search-input"
                            placeholder="Find team..."
                            value={searchInput}
                            onChange={handleSearchInputChange}
                        />
                        <div className="tab-block teamexplorer-list">
                            {teamList.map((team) => (
                                <div className="teamexplorer-item" key={team.id} onClick={() => { navigate(`/team/${team.id}`) }}>
                                    <img src={team.logo || teamDefaultImg} className="teamexplorer-logo" />
                                    <div className="teamexplorer-details">
                                        <div className="teamexplorer-name">{team.name}</div>
                                        <div className="teamexplorer-league">{team.league}</div>
                                    </div>
                                    <svg width="20" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 31.6654L25 19.9987L15 8.33203" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            ))}
                        </div> */}

                    </div>


                </div>
            </div >

        </>


    );
};
