import React from 'react';
import { useTranslation } from 'react-i18next';
import "./modals.scss";
import shoppingBasketIcon from "./shoppingBasketIcon.png";
import { useNavigate } from 'react-router-dom';

function ToFood({ onClick }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    function handleNavigate() {
        navigate("/shop/niki");
    }

    return (
        <div className='outer_container_coming'>
            <span className="close" onClick={onClick}>&times;</span>

            <div className='container_glow_coming'>
                <img src={shoppingBasketIcon} className="img_modals" alt="Basket" />
                <h2>{t('toFood.title')}</h2>
                <div className='text_modal'>{t('toFood.description')}</div>
                <button className="shop-button" onClick={handleNavigate}>{t('toFood.button')}</button>
            </div>
        </div>
    );
}

export default ToFood;
