import React from 'react';
import { useTranslation } from 'react-i18next';
import "./loading.scss";
import Loading_block from './Loading_block';
import img1 from "./1.png";
import img2 from "./2.png";
import img3 from "./3.png";
import img4 from "./4.png";
import img5 from "./img5.png";
import loading from "./loading.png"
import { useAppData } from '../../AppContext';

function Loading() {
    const { t } = useTranslation();
    const { progress } = useAppData();

    return (
        <div className='loading'>
            <img src={loading} style={{ width: "100%" }}></img>
        </div>
    );
}

export default Loading;
