import React from 'react';
import "./thanks.scss";
import img from "./img.png";
import { useAppData } from '../../AppContext';
import { useTranslation } from 'react-i18next';

function ThanksNiki({ onClick, coins }) {
    const { t } = useTranslation();
    const { handleEditCoins, setpassiveIncome } = useAppData();

    const handleClick = () => {
        setpassiveIncome(0);
        handleEditCoins(coins)
    };

    return (
        <div className='outer_container_thanks'>
            <div className='container_glow_thanks'>
                <img src={img} className='img_thanks' alt="Thanks" />
                <div className='glow_thanks'></div>
                <div className='coins_thanks'>
                    <img src="https://nikicoin-bucket.s3.eu-north-1.amazonaws.com/images/10243319+1.png" className="coin_img_thanks" alt="Coin-icon" />
                    {coins.toLocaleString('en-US')}
                </div>
                <div className='text_thanks'>{t('thanksNiki.memesBroughtCoins')}</div>
                <button className='button_thanks' onClick={handleClick}>{t('thanksNiki.thanksNiki')}</button>
            </div>
        </div>
    );
}

export default ThanksNiki;
