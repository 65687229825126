import React from 'react'
import gem from "./gem.png"
import food from "./food.png"
import pointer from "./pointer.png"
import huina from "./rozovaya.png"
import coin from "../../coin.png"

function Petcard({ pet }) {
    return (
        <div className='d_flex_choose mt50'>
            <div className='pet_name_container'>
                <img src={pet.logo} alt={pet.name} />
                <div>{pet.name}</div>
            </div>
            <div className='info_container'>
                <div className='d_flex_choose info_pet'>
                    <img src={food} />
                    {pet.food_per_day} Food Per Day
                </div>
                <div className='d_flex_choose info_pet'>
                    <img src={gem} />
                    {pet.gems_per_day} Gem Per Day
                </div>
                <div className='d_flex_choose info_pet'>
                    <img src={coin} />
                    {pet.coins_per_day} Coins per Day
                </div>
                <div className='button_select'>
                    Select
                    <img src={pointer}></img>
                </div>
            </div>
        </div>
    );
}

export default Petcard;
