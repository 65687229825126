import React from 'react';
import "./PlayOnPhone.scss";
import nikiplay from "./nikiplay.png";
import qr from "./qr.png";
import { useTranslation } from 'react-i18next';

function PlayOnPhone() {
    const { t } = useTranslation();

    const openLink = (url) => {
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openLink(url);
        } else {
            window.open(url, '_blank');
        }
    };

    return (
        <div className="mobile-prompt-container">
            <div className='outer_container_play'>
                <div className='container_glow_play'>
                    <div className="mobile_prompt_text">
                        {t('playOnPhone.bestExperience')}<span className="gradient-text"> {t('playOnPhone.playOnPhone')}</span>
                    </div>

                    <img src={qr} style={{ width: "75%" }} alt="QR Code" />

                    <div className="btn_container_play">
                        <button className="btn_play" onClick={() => openLink('https://t.me/nikicompany')}>
                            <img src='https://nikicoin-bucket.s3.eu-north-1.amazonaws.com/images/other/telegram.png' alt="Telegram" />
                            {t('playOnPhone.telegram')}
                        </button>
                        <button className="btn_play" onClick={() => openLink('https://nikicompany.com')}>
                            <img src='https://nikicoin-bucket.s3.eu-north-1.amazonaws.com/images/other/niki.png' alt="Website" />
                            {t('playOnPhone.website')}
                        </button>
                        <button className="btn_play" onClick={() => openLink('https://twitter.com/Nikicompany')}>
                            <img src='https://nikicoin-bucket.s3.eu-north-1.amazonaws.com/images/other/x-logo.png' alt="Twitter" />
                            {t('playOnPhone.twitter')}
                        </button>
                        <button className="btn_play" onClick={() => openLink('https://wrapper.playdeck.io/?url=https://games.playdeck.io/games/NikiBoom/index.html?telegram%3Dtrue%26lang%3Dru&token=eyJraWQiOiJiYzljZjY1OS05NWQyLTQ1NWQtYmYxMS1jMTJmMTM0ODhhMzUiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIyNDE1MTI0MDMiLCJpc3MiOiJodHRwczpcL1wvb2EudG9ucGxheS5pbyIsImdhbWVLZXkiOiI0N3M5UWtQSE4yIiwiZ2FtZVNob3J0TmFtZSI6ImdhbWUyMSIsImV4cCI6MTcxNTkzOTMwNiwidHlwZSI6IkdBTUUiLCJsb2NhbGUiOiJydSIsImlhdCI6MTcwNzI5OTMwNiwiYXV0aG9yaXRpZXMiOlsiUk9MRV9VU0VSIl0sInVzZXJuYW1lIjoic2t2b3J0c292ZGRkIiwibGFiZWxzIjpbIndoZWVsX3YyLjUiLCJ3aGVlbF9ncmlkXzE0MTJfeWVsbG93Il19.kUcwqdA5HOd3F8vB6AkXIvWE2_BZmN9c_yySK6BFnmn0QJvHoxA5AnJRkLqKyGb2Mx-cAA7GQjs_jUCmfmceRtUpX1GbWcUIjmRYlROh1c2UwH9fPMu-SvnIJPNmQkUxgJBFDSTO2XhOiL3WYfxYIQol3OQn6lBLuw4silGx0UE76itfLAZeygqpPFYJvRlM3SQigg14BRrS8vTmwyphV5_zZWfva-zw1f3u1cF9pX2PTH3qHhcWfvc4_gvsMIyKI3eZb83aw5e78m3kDjNiF9ipfX1t5j-PtAq-ymsFwRueCLpHx_P--6xXOcxgP_yZV5Cen6TOc1DKO-ea7WMRkQ&entropy=1S7bx&gameSessionId=2c97d1bc-5d96-4cec-a305-c7955d7299e2&mode=gameapp#telegramId=241512403&tgShareScoreUrl=tg://share_game_score?hash=ucptv6YVHRFGsvgl3gdcH1F4Okz-8R1NRt5JgzzCxvL9wWDiyDM5qZtOxMVBwYOY')}>
                            <img src={nikiplay} alt="Niki Boom" />
                            {t('playOnPhone.nikiBoom')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlayOnPhone;
