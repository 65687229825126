import React from 'react';
import "./settings.scss";
import Back from '../Back';
import { useTranslation } from 'react-i18next';
import pointer from "./pointer.png";

function LenguaguePicker() {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div>
            <Back />
            <div className='settings_text'>{t('settings')}</div>
            <div className='outer-container_settings'>
                <div className='container_glow_settings'>
                    <div className="settings-container">
                        <div className="settings-block">
                            <div
                                className={`lenguague_con ${currentLanguage === 'en' ? 'selected' : ''}`}
                                onClick={() => changeLanguage('en')}
                            >
                                <div>
                                    <div>English (en)</div>
                                    {currentLanguage === 'en' && <div className='selected_lenguague'>{t('selected')}</div>}
                                </div>
                                <img style={{ height: "15px" }} src={pointer} alt="Selected" />
                            </div>

                            <div
                                className={`lenguague_con ${currentLanguage === 'ru' ? 'selected' : ''}`}
                                onClick={() => changeLanguage('ru')}
                            >
                                <div>
                                    <div>Русский (ru)</div>
                                    {currentLanguage === 'ru' && <div className='selected_lenguague'>{t('selected')}</div>}
                                </div>
                                <img style={{ height: "15px" }} src={pointer} alt="Selected" />
                            </div>

                            <div
                                className={`lenguague_con ${currentLanguage === 'uk' ? 'selected' : ''}`}
                                onClick={() => changeLanguage('uk')}
                            >
                                <div>
                                    <div>Українська (uk)</div>
                                    {currentLanguage === 'uk' && <div className='selected_lenguague'>{t('selected')}</div>}
                                </div>
                                <img style={{ height: "15px" }} src={pointer} alt="Selected" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LenguaguePicker;
