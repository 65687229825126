import React from 'react';
import done from './done.png';
import "./tasks.scss";
import coin from '../../pages/dollar_coin.png';

function Task({ task, coins, icon, status, onClick }) {
    const handleClick = () => {
        if (status !== "CM") {
            onClick();
        }
    };

    return (
        <div className={"tasks_con" + (status === "CM" ? " disabled" : "")} onClick={handleClick}>
            <div className='additionalWrap'>
                <div className="iconWrapper ">
                    <img src={icon} className='img_friends' alt="Task Icon" />
                </div>
                <div className="infoWrapper">
                    <h2 className="name">{task}</h2>
                    <span className="coins_reward">{coins.toLocaleString('en-US')} <img src={coin} className="coin_icon_task" alt="Coin-icon" /></span>
                </div>
            </div>
            {status === "CM" ? (
                <div className="task_status">
                    <img src={done} alt="Done" />
                </div>
            ) : (
                <div className='task_status' />
            )}
        </div>
    );
}

export default Task;
