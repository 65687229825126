import React, { useState } from 'react';
import './settings.scss';
import lenguague from './lenguague.png';
import wallet from './wallet.png';
import { useTranslation } from 'react-i18next';
import Back from '../Back';
import { useNavigate } from 'react-router-dom';
import ComingSoon from '../ComingSoon/ComingSoon';
function Settings() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const [show, setShow] = useState(false)

    return (
        <div>
            {show ? <ComingSoon onClick={() => setShow(false)} /> : ""}
            <Back />
            <div className='settings_text'>{t('settings')}</div>
            <div className='outer-container_settings'>
                <div className='container_glow_settings'>
                    <div className="settings-container">
                        <div className="settings-block">
                            <div className="settings-item" onClick={() => navigate("/lenguague_picker")}>
                                <div className="settings-text">
                                    <div className="settings-title">{t('changeLanguage')}</div>
                                    <div className="settings-subtitle">{i18n.language}</div>
                                </div>
                                <img className="settings-icon" src={lenguague} alt="language icon" />
                            </div>

                            <div className="settings-item" onClick={() => setShow(true)}>
                                <div className="settings-text">
                                    <div className="settings-title">{t('connectWallet')}</div>
                                    <div className="settings-subtitle">{t('none')}</div>
                                </div>
                                <img className="settings-icon" src={wallet} alt="wallet icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default Settings;
