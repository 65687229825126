import React, { useState } from 'react';
import './petcard.scss';
import { useAppData } from '../../../../AppContext';
import ComingSoon from '../../../ComingSoon/ComingSoon';
import Back from '../../../Back';
import { useTranslation } from 'react-i18next';

const PetCard = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const { Pet, dollar_coin } = useAppData();

    function formatNumber(num) {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        } else {
            return num;
        }
    }

    return (
        <>
            <Back />
            {show && <ComingSoon onClick={() => setShow(false)} />}
            <div className="pet_card">
                <div className='glow_petcard'></div>
                <div className='header_petcard_background'></div>
                <div className="pet-avatar">
                    <img src={Pet.logo_with_background} className='petcard_img' alt="Pet Avatar" />
                </div>
                <h1 onClick={() => setShow(true)}>{t('petCard.mintNFT')}</h1>
                <h2>{Pet.name}</h2>
                <div className="pet_details">
                    <div className='pet_details_text'>{formatNumber(Pet.total_spent)}
                        <img src={dollar_coin} style={{ height: "25px" }} alt="Dollar Coin" />
                    </div>
                    <div style={{ fontSize: "1.4rem" }}>{Pet.price_usd}$</div>
                    <div className='pet_details_text'>{t('petCard.level')} {Pet.level}</div>
                </div>
                <div className="actions">
                    <button onClick={() => setShow(true)} className="button_long_friend">{t('petCard.mintNFT')}</button>
                    <button onClick={() => setShow(true)} className="button_long_friend button-purple">{t('petCard.howToSell')}</button>
                </div>
            </div>
        </>
    );
}

export default PetCard;
