import React from 'react';
import './loading.scss';

const Loading_block = ({ img, text, subtext }) => {
    return (
        <div className="loading_block">
            <img src={img} className="emoji"></img>
            <div className="text-container">
                <div className="text">{text}</div>
                <div className="subtext">{subtext}</div>
            </div>
        </div>
    );
};

export default Loading_block;
