import React from 'react';
import "./upgrade.scss";
import coin from '../../pages/dollar_coin.png'

const formatNumber = (num) => {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(0) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(0) + 'K';
    }
    return num;
};

function Upgrade({ title, icon, profit, lvl, upgrade_cost }) {

    return (
        <div className="upgrade1">
            <div className="profit1">
                <div className="icon">
                    <img src={icon} alt="upgrade_icon" />
                </div>
                <div className="info2">
                    <div className="title">{title}</div>
                    <div className="profit_info">
                        <div className="text">Profit per hour</div>
                        <div className="profit2">
                            <img src={coin} alt="coin" />
                            {formatNumber(profit)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="info1">
                <div className="lvl">Tier {lvl}</div>
                <div className="separattor" />
                <div className="upgrade_cost">
                    <img src={coin} alt="coin" />
                    {formatNumber(upgrade_cost)}
                </div>
            </div>
        </div>
    );
}

export default Upgrade;
