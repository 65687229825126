import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-resources-to-backend';

import translationEN from './locale/en/translationEN.json'
import translationRU from './locale/ru/translationRU.json'
import translationUK from './locale/uk/translationUK.json'
// import translationRU from '../locale/ru/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
  uk: {
    translation: translationUK,
  },
};

i18n
  .use(Backend((language, namespace, callback) => {
    const resource = resources[language] ? resources[language][namespace] : null;
    callback(null, resource || {});
  }))
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    detection: {
      order: ['cookie', 'localStorage', 'path', 'htmlTag', 'subdomain'],
      caches: ['cookie'],
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
