import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navigate from '../../NavigateBtn/Navigate';
import "./PetNaming.scss";
import { getPet, buyPet } from '../../../http';
import { useAppData } from '../../../AppContext';
import { useTranslation } from 'react-i18next';

function PetNaming() {
    const { t } = useTranslation();
    const { PetId } = useParams();
    const navigate = useNavigate();
    const [pet, setPet] = useState(null);
    const [petName, setPetName] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { getPlayerPetData } = useAppData();

    const getPetData = async () => {
        try {
            const data = await getPet(PetId);
            if (data) {
                setPet(data);
                setLoading(false);
            } else {
                throw new Error(t('petNaming.noData'));
            }
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (PetId) {
            getPetData();
        } else {
            setError(t('petNaming.noPetId'));
            setLoading(false);
        }
    }, [PetId]);

    const handleInputChange = (e) => {
        setPetName(e.target.value);
    };

    const handleSubmit = async () => {
        if (!petName.trim()) {
            alert(t('petNaming.enterNameAlert'));
            return;
        }
        try {
            await buyPet(PetId, petName);
            getPlayerPetData();
            navigate("/");
        } catch (error) {
            console.error('Error buying pet:', error);
            setError(t('petNaming.buyPetError'));
        }
    };

    if (loading) {
        return <div>{t('petNaming.loading')}</div>;
    }

    if (error) {
        return <div>{t('petNaming.error', { error })}</div>;
    }

    return (
        <div className='outer-container'>
            <div className='container_p40'>
                <Navigate position="left" navigateTo="/choose_pet" />
                <div className='pet_container'>
                    <img className='pet_img_naming' src={pet?.logo} alt="Your Pet"></img>
                    <div className='pet-glow'></div>
                </div>
                <div className='center'>
                    <div className='showcase'>
                        <span className='gradient rotated wrapper'>
                            <input
                                className='input'
                                type='text'
                                placeholder={t('petNaming.placeholder')}
                                value={petName}
                                onChange={handleInputChange}
                            />
                        </span>
                    </div>
                    <div className='under_text'>{t('petNaming.writeName')}</div>
                </div>
                <button className='btn_naming_submit' onClick={handleSubmit}>{t('petNaming.next')}</button>
            </div>
        </div>
    );
}

export default PetNaming;
