import React, { useState } from 'react'
import "./map.scss"
import map from "./map.png"
import city from "./city.png"
import ice from "./ice.png"
function Map({ onClick }) {

    const [show, setShow] = useState(map)


    return (
        <div className='outer_container_map'>
            <span className="close" onClick={onClick}>&times;</span>
            <div className='talesTelegramia_map'>Tales of telegramia</div>

            <div className='container_glow_map'>
                <div className='island1' onClick={() => setShow(ice)}></div>
                <img src={show} className='map'>
                </img>
                <div className='island2' onClick={() => setShow(city)}></div>

            </div>
        </div>
    )
}

export default Map