import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getTeam } from '../../http';
import { useAppData } from '../../AppContext';

import coinImg from '../../images/icons/coin.png';
import defaultLogoImg from '../../images/teams/teamlogo_1.jpg';
import Back from '../Back';

export const Team = () => {
    const { teamId } = useParams();
    const [team, setTeam] = useState([]);
    const { user, joinTeam, exitTeam } = useAppData();

    useEffect(() => {
        const fetchTeam = async () => {
            const fetchedTeam = await getTeam(teamId);
            if (fetchedTeam) {
                setTeam(fetchedTeam);
            }
        };
        fetchTeam();
    }, []);

    const handleExitTeam = () => {
        exitTeam();
    };

    const handleJoinTeam = () => {
        joinTeam(teamId);
    };

    console.log(user)
    return (
        <div className='tab team-tab'>
            <Back />
            {team ? (
                <div className='team_background_observe' >
                    <div className="header_league">
                        <div className="team-info">
                            <img className='team-logo' src={team.logo ? team.logo : defaultLogoImg} alt={team.name} />
                            <h1 className='team-title'>{team.name}</h1>
                            <div className="team-details">
                                <div className='team-details-case'>
                                    <h2><img src={coinImg} className="coin-icon" alt="Coin-icon" /> {team.coins}</h2>
                                    <p>Total score</p>
                                </div>
                                <div className='team-details-case'>
                                    <h2>{team.participants}</h2>
                                    <p>Participants</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            ) : (
                <p>Loading...</p>
            )}

            <div className='outer-container_team_observe'>
                <div className='container_glow_team_observe'>
                    {user.team === teamId ? (
                        <button className='button-long button-orange' onClick={handleExitTeam}>Exit team</button>
                    ) : (
                        <button className='button_team' onClick={handleJoinTeam}>{user.team ? ('Change team') : ('Join team')}</button>
                    )}
                </div>
            </div>
        </div>
    );
};
